<template>
  <n-modal v-bind="$props" :custom-title="this.$t('label.rolePrivileges')">
    <div class="table-responsive" :style="{ overflowY: 'auto', maxHeight: `${innerHeight - 200}px` }">
      <n-tree-table :columns="columns" :records="menus">
        <template #name="{ row }">
          <Row type="flex" class="parent-name">
            <div><i :class="`${row.icon}`"></i></div>
            <span>{{ $t(`menu.${row.name}.self`) }}</span>
          </Row>
        </template>
        <template #subName="{ row }">
          <div class="text-left" style="padding-left: 48px">{{ $t(`menu.${row.name}`) }}</div>
        </template>
        <template #read="{ row }">
          <Checkbox
            v-model="row.readChecked"
            :disabled="disabled"
            :indeterminate="row.readIndeterminate"
            @on-change="hitRead($event, row)"
          />
        </template>
        <template #subRead="{ row, parent }">
          <Checkbox v-model="row.readChecked" :disabled="!row.readable || disabled" @on-change="hitRead($event, row, parent)" />
        </template>
        <template #write="{ row }">
          <Checkbox
            v-model="row.writeChecked"
            :disabled="!row.writable || disabled"
            :indeterminate="row.writeIndeterminate"
            @on-change="hitWrite($event, row)"
          />
        </template>
        <template #subWrite="{ row, parent }">
          <Checkbox v-model="row.writeChecked" :disabled="!row.writable || disabled" @on-change="hitWrite($event, row, parent)" />
        </template>
      </n-tree-table>
    </div>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { mapGetters } from 'vuex';
import { grant, getMenuPrivilege, getRolePrivilege, getOperatorPrivilege } from '@/api/operator/operator-role';

export default NModal.extend({
  name: 'ModalPrivileges',

  components: { NModal },

  props: {
    roleId: Number,
  },

  data() {
    return {
      menus: [],
      columns: [
        { title: 'name', dataIndex: 'name', contentSlot: 'name', subContentSlot: 'subName', width: '40%' },
        { title: 'read', dataIndex: 'readable', contentSlot: 'read', subContentSlot: 'subRead', width: '20%' },
        { title: 'write', dataIndex: 'writable', contentSlot: 'write', subContentSlot: 'subWrite', width: '' },
      ],
    };
  },

  computed: {
    ...mapGetters('layout', ['innerHeight']),
  },

  mounted() {
    const roleId = this.roleId;
    const operatorId = this.$store.state.profile.operatorId;
    let p1 = getRolePrivilege(roleId);
    let p2 = getMenuPrivilege(roleId);
    let p3 = getOperatorPrivilege(operatorId);
    Promise.allSettled([p1, p2, p3]).then(result => {
      this.menus = this.buildMenus(result[1].value);
    });
  },

  methods: {
    buildMenus(menus) {
      let r = [];
      for (let menu of menus) {
        this.setParent(menu);
        r.push(menu);
      }
      return r;
    },

    doValidate() {
      return Promise.resolve({ type: 'SUCCESS' });
    },

    doSubmit() {
      let privileges = [];
      let role = this.roleId;
      this.menus.forEach(menu => {
        menu.subs.forEach(sub => {
          if (sub.readChecked) privileges.push(sub.readPrivilege);
          if (sub.writeChecked) privileges.push(sub.writePrivilege);
        });
      });
      return grant({ role, privileges: privileges.filter(p => p !== 0) }); // filter valid privilege
    },

    hitRead(v, menu, parent) {
      menu.readChecked = v;
      if (parent) {
        if (!v) menu.writeChecked = v;
        this.setParent(parent);
      } else {
        menu.subs.forEach(s => (s.readChecked = v));
        if (!v) {
          menu.writeChecked = v;
          menu.subs.forEach(s => (s.writeChecked = v));
        }
        this.setParent(menu);
      }
    },

    hitWrite(v, menu, parent) {
      menu.writeChecked = v;
      if (parent) {
        if (v) menu.readChecked = v;
        this.setParent(parent);
      } else {
        menu.subs.forEach(s => (s.writeChecked = v));
        if (v) {
          menu.readChecked = v;
          menu.subs.forEach(s => (s.readChecked = v));
        }
        this.setParent(menu);
      }
    },

    setParent(parent) {
      let subs = parent.subs;
      parent.readable = subs.every(s => s.readable);
      parent.writable = subs.every(s => s.writable);
      parent.readChecked = subs.every(s => s.readChecked);
      parent.writeChecked = subs.every(s => s.writeChecked);
      parent.readIndeterminate = !!subs.find(s => s.readChecked) && !!subs.find(s => !s.readChecked);
      parent.writeIndeterminate = !!subs.find(s => s.writeChecked) && !!subs.find(s => !s.writeChecked);
    },
  },
});
</script>

<style lang="scss" scoped>
::v-deep .n-tree-table {
  th,
  td {
    height: 36px !important;
    font-size: 13px !important;

    .parent-name {
      padding-left: 12px;

      div {
        width: 35px;
        text-align: center;

        i {
          margin-top: 5px;
        }
      }

      span {
        margin-top: 2px;
      }
    }

    .ivu-checkbox-wrapper {
      margin: 0 !important;
    }
  }
}
</style>
