import { $fetch, $post, $put } from '@/services/axios';

export function fetch(params) {
  return $fetch(`operator/roles`, params);
}

export function validate(model) {
  return $post(`operator/role/validate`, model);
}

export function create(model) {
  return $put(`operator/role`, model);
}

export function update(model) {
  return $post(`operator/role/${model.id}`, model);
}

export function grant(payload) {
  return $post(`operator/role/${payload.role}/privileges`, {
    privileges: payload.privileges.toString(),
  });
}

export function getMenuPrivilege(roleId) {
  return $fetch(`operator/role/${roleId}/menus`);
}

export function getRolePrivilege(roleId) {
  return $fetch(`operator/role/${roleId}/privileges`);
}

export function getOperatorPrivilege(operatorId) {
  return $fetch(`operator/operator/${operatorId}/privileges`);
}
