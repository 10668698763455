<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props" :width="500">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col1>
        <ns-company v-model="model.companyId" field-name="companyId" :error="error.companyId" :disabled="isEdit || disabled" />
      </n-row-col1>
      <n-row-col1>
        <n-field-enum v-model="model.status" field-name="status" enum-name="AdminRoleStatus" label="status" :error="error.status" :disabled="disabled" />
      </n-row-col1>
      <n-row-col1>
        <n-field-text v-model="model.name" field-name="name" label="name" :error="error.name" :disabled="disabled" />
      </n-row-col1>
      <n-row-col1>
        <n-field-textarea v-model="model.comment" field-name="comment" label="comment" :rows="5" :error="error.comment" :disabled="disabled" />
      </n-row-col1>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { create, update, validate } from '@/api/operator/operator-role';

export default NModal.extend({
  name: 'ModalRole',
  components: { NModal },
  methods: {
    doValidate(model) {
      return validate(model);
    },
    doSubmit(model) {
      return this.isCreate ? create(model) : update(model);
    },
  },
});
</script>
