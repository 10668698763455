<template>
  <!-- prettier-ignore -->
  <index-content>
    <template v-if="hasRW" #options>
      <div class="n-button-box">
        <nb-add @on-click="openModal" />
      </div>
    </template>
    <search-condition>
      <ns-company v-model="condition.companyId" all-option @on-change="load" />
    </search-condition>
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <tr>
            <th class="id">{{ $t('label.id') }}</th>
            <th class="col-weight-1">{{ $t('label.company') }}</th>
            <th class="col-weight-1">{{ $t('label.name') }}</th>
            <th class="col-weight-1">{{ $t('label.status') }}</th>
            <th class="col-weight-4">{{ $t('label.comment') }}</th>
            <th class="n-table-timestamp">{{ $t('label.lastUpdate') }}</th>
            <th v-table-action>{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.id }}</td>
            <td v-selection:[`company.company`]="row.companyId"></td>
            <td>{{ row.name }}</td>
            <td v-enums:AdminRoleStatus="row.status"></td>
            <td class="n-ellipsis" :title="row.comment">{{ row.comment | hyphen }}</td>
            <td>{{ row.updateDatetime | datetime }}</td>
            <td class="n-button-box">
              <nb-modal text="privileges" custom-icon="fa fa-bars" :disabled="row.status !== $enums.AdminRoleStatus.NORMAL" @on-click="openPrivilegesModal(row.id)" />
              <nb-modal @on-click="openModal(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </index-content>
</template>

<script>
import OperatorView from './view';
import ModalRole from './ModalRole';
import ModalPrivileges from './ModalPrivileges';
import { fetch } from '@/api/operator/operator-role';

export default OperatorView.extend({
  name: 'OperatorRole',
  data() {
    return {
      preload: true,
      condition: {
        companyId: 0,
      },
    };
  },
  methods: {
    doLoad() {
      let params = { ...this.condition };
      return fetch(params);
    },
    parse(rows) {
      this.records = rows;
    },
    openModal(model = {}) {
      this.createModal(ModalRole, { model, on: this });
    },
    openPrivilegesModal(roleId) {
      this.createModal(ModalPrivileges, { props: { roleId, escapable: true }, on: this });
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  min-width: 850px;

  th.id {
    width: 4rem;
  }

  th.rw-action {
    width: 9.5rem;
  }

  th.ro-action {
    width: 10rem;
  }
}
</style>
